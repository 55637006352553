import {
  updateBedInfo
} from '@/services'
export default {
  tableItems: [
    {
      prop: 'id',
      label: 'ID',
      width: '100px',
      classname: 'text-blue'
    },
    {
      prop: 'institution_name',
      label: 'Institution',
      classname: ''
    },
    {
      prop: 'name',
      label: 'Bed Number',
      classname: ''
    },
    {
      prop: 'active',
      label: 'Active',
      classname: '',
      isSwitch: true,
      methods: (id, active) => updateBedInfo(id, {bed: {active}})
    }
  ],
  tableActions: [
    {
      label: 'Edit',
      url: '/resources/beds/update',
      type: 'jump'
    }
  ],
  pageActions: [
    {
      label: 'Add',
      url: '/resources/beds/update',
      type: 'jump'
    }
  ]
}