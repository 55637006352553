<template>
  <TableViewFrame
    :tableItems="tableItems"
    :actions="actions"
    :getTableDataFunc="getBedList"
    table-type="beds"
    :hasSwitchPermit="hasSwitchPermit"
    :btnActions="pageActions"
  />
</template>
<script>
import TableViewFrame from '@/components/TableViewFrame'
import {
  getBedList
} from '@/services'
import { permitActionMixin } from '@/mixin/permitActionMixin'
import utils from '@/utils/Utils'
import jsonData from './jsonData'
export default {
  components: {
    TableViewFrame
  },
  mixins: [permitActionMixin],
  data () {
    return {
      tableItems: jsonData.tableItems,
      actions: jsonData.tableActions,
      pageActions: jsonData.pageActions,
      getBedList,
      hasSwitchPermit: false
    }
  },
  created () {
    this.initPermits()
  },
  methods: {
    async initPermits () {
      await this.initActionData(jsonData, 'beds')
      this.hasSwitchPermit = utils.hasPermission(this.permitActions, 'beds', 'update')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>