<template>
  <el-card class="height100percent">
    <div class="d-flex flex-wrap">
      <el-button
        v-for="(item, index) in btnActions"
        :key="index"
        :type="item.btnType || 'primary'"
        @click="$router.push(item.url)"
        class="mr10"
      >
        {{item.label}}
      </el-button>
    </div>
    <div class="mt20 table-wrapper">
      <CommonTable
        v-bind="$attrs"
      />
    </div>
  </el-card>
</template>
<script>
import CommonTable from '@/components/CommonTable/index.vue'
export default {
  components: {
    CommonTable
  },
  props: {
    btnActions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-card__body {
    height: 100%;
  }
}
.table-wrapper {
  height: calc(100% - 72px);
}
</style>